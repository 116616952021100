<template>
    <div>
        <div
            style="
                font-size: 34px;
                font-weight: bold;
                margin-bottom: 22px;
                color: red;
            "
        >
            <p>
                <span style="font-size: 14px">Sử dụng class background:</span>
                bg-*
            </p>
            <p>
                <span style="font-size: 14px">Sử dụng class color: </span
                >color-*
            </p>
        </div>
        <div>
            <p class="is-title">Primary color</p>
        </div>
        <div>
            <div class="section-color">
                <div v-for="(bg, index) in background" :key="index">
                    <div class="block-color" :class="`bg-primary-${bg}`"></div>
                    <div class="block-info">
                        <span>primary-{{ bg }}</span>
                    </div>
                    <p class="block-info" :class="`color-primary-${bg}`">
                        <span>primary-{{ bg }}</span>
                    </p>
                </div>
            </div>
        </div>
        <div>
            <p class="is-title" style="padding-top: 42px">Secondary Color</p>
        </div>
        <div>
            <div class="section-color">
                <div v-for="(bg, index) in background" :key="index">
                    <div
                        class="block-color"
                        :class="`bg-secondary-green-${bg}`"
                    ></div>
                    <div class="block-info">
                        <span>secondary-green-{{ bg }}</span>
                    </div>
                    <p
                        class="block-info"
                        :class="`color-secondary-green-${bg}`"
                    >
                        <span>secondary-green-{{ bg }}</span>
                    </p>
                </div>
            </div>
        </div>
        <div>
            <p class="is-title" style="padding-top: 42px">Warning Color</p>
        </div>
        <div>
            <div class="section-color">
                <div v-for="(bg, index) in background" :key="index">
                    <div
                        class="block-color"
                        :class="`bg-secondary-orange-${bg}`"
                    ></div>
                    <div class="block-info">
                        <span>secondary-orange-{{ bg }}</span>
                    </div>
                    <p
                        class="block-info"
                        :class="`color-secondary-orange-${bg}`"
                    >
                        <span>secondary-orange-{{ bg }}</span>
                    </p>
                </div>
            </div>
        </div>
        <div>
            <p class="is-title" style="padding-top: 42px">Danger Color</p>
        </div>
        <div>
            <div class="section-color">
                <div v-for="(bg, index) in background" :key="index">
                    <div
                        class="block-color"
                        :class="`bg-secondary-red-${bg}`"
                    ></div>
                    <div class="block-info">
                        <span>secondary-red-{{ bg }}</span>
                    </div>
                    <p class="block-info" :class="`color-secondary-red-${bg}`">
                        <span>secondary-red-{{ bg }}</span>
                    </p>
                </div>
            </div>
        </div>
        <div>
            <p class="is-title" style="padding-top: 42px">Purple Color</p>
        </div>
        <div>
            <div class="section-color">
                <div v-for="(bg, index) in background" :key="index">
                    <div
                        class="block-color"
                        :class="`bg-secondary-purple-${bg}`"
                    ></div>
                    <div class="block-info">
                        <span>secondary-purple-{{ bg }}</span>
                    </div>
                    <p
                        class="block-info"
                        :class="`color-secondary-purple-${bg}`"
                    >
                        <span>secondary-purple-{{ bg }}</span>
                    </p>
                </div>
            </div>
        </div>

        <div>
            <p class="is-title" style="padding-top: 42px">Neutral Color</p>
        </div>
        <div>
            <div class="section-color">
                <div v-for="(bg, index) in background" :key="index">
                    <div class="block-color" :class="`bg-neutral-${bg}`"></div>
                    <div class="block-info">
                        <span>neutral-{{ bg }}</span>
                    </div>
                    <p class="block-info" :class="`color-neutral-${bg}`">
                        <span>neutral-{{ bg }}</span>
                    </p>
                </div>
            </div>
        </div>

        <div>
            <p class="is-title" style="padding-top: 42px">Popup Color</p>
        </div>
        <div>
            <div class="section-color">
                <div :key="index">
                    <div class="block-color" :class="`bg-overlay-popup`"></div>
                    <div class="block-info">
                        <span>overlay-popup</span>
                    </div>
                    <p class="block-info" :class="`color-overlay-popup`">
                        <span>overlay-popup</span>
                    </p>
                </div>
            </div>
        </div>

        <div>
            <p class="is-title" style="padding-top: 42px">Bottomsheet Color</p>
        </div>
        <div>
            <div class="section-color">
                <div :key="index">
                    <div
                        class="block-color"
                        :class="`bg-overlay-bottom-sheet`"
                    ></div>
                    <div class="block-info">
                        <span>overlay-bottom-sheet</span>
                    </div>
                    <p class="block-info" :class="`color-overlay-bottom-sheet`">
                        <span>overlay-bottom-sheet</span>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Color",
    components: {},
    data() {
        return {
            background: [25, 50, 100, 200, 300, 400, 500, 600, 700, 800, 900],
        };
    },
    created() {
        
    }
};
</script>

<style lang="scss" scoped>
.is-title {
    font-weight: 600;
    font-size: 25px;
    color: #000000;
}

.section-color {
    padding-top: 24px;
    display: flex;
    gap: 40px;
    .block-info {
        padding-top: 10px;
    }
}
.section-bg {
    padding-top: 24px;
    display: flex;
    gap: 40px;
    .block-info {
        padding-top: 10px;
    }
}
.section-neutral {
    padding-top: 24px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 40px;
    .block-info {
        padding-top: 10px;
    }
}
.block-color {
    width: 100px;
    height: 100px;
    border-radius: 4px;
}
.is-title-block-info {
    font-weight: 400;
    font-size: 14px;
    color: #000000;
    line-height: 18px;
}

.block-info {
    font-size: 12px;
}
</style>
